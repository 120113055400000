/*

 Schneider Catalog Number Selection

 Copyright 2018 Schneider Electric

 2018.06.26

*/
window.cnsOverlayOpen = cnsOverlayOpen;
window.cnsOverlayClose = cnsOverlayClose;
window.cnsOverlayZIndex = 1E4;
window.cnsOverlayMargin = 4;
window.cnsTwoColumnView = !0;
window.cnsCloseButton = !0;
window.cnsLanguage = "";

function cnsUrlPath() {
  return "//ezlist.schneider-electric.com/"
}

function cnsOverlayOpen(d, e) {
  var b = cnsOverlayNextHighZIndex("div");
  cnsCheckOverlayMargin();
  if (null === document.getElementById("cnsOverlayWrapper")) {
    var a = document.createElement("div");
    a.style.cssText = "position:fixed;width:100%;height:100%;top:0px;left:0px;display:none;z-index:" + b + ";";
    a.id = "cnsOverlayWrapper";
    document.body.appendChild(a);
    b += 1
  }
  a = "";
  "string" == typeof window.cnsLabelAddToList && (a += "&button=" + window.cnsLabelAddToList);
  a = !1 === window.cnsCloseButton ? a + "&close=false" : !0 === window.cnsCloseButton ?
    a + "&close=true" : a + ("&close=" + window.cnsCloseButton);
  "undefined" !== typeof e && (a += "&subset=" + e);
  a+="&button=Add%20to%20Cart";
  "string" == typeof window.cnsLanguage && "" != window.cnsLanguage && (a += "&lang=" + cnsLanguage);
  "string" == typeof window.cnsInitialHost && (a += "&ihost=" + window.cnsInitialHost);
  "undefined" !== typeof window.cnsDist && "" != window.cnsDist && (a += "&dist=" + window.cnsDist);
  var c = location.protocol;
  "file:" == c && (c = "http:");
  document.getElementById("cnsOverlayWrapper").innerHTML = '<div style="position:fixed;width:100%;height:100%;top:0px;left:0px;opacity:0.5;z-index:' +
    (b + 1) + ';background:#000;"></div><div style="position:fixed;width:' + (100 - 2 * window.cnsOverlayMargin) + "%;height:" + (100 - 2 * window.cnsOverlayMargin) + "%;top:" + window.cnsOverlayMargin + "%;left:" + window.cnsOverlayMargin + "%;z-index:" + (b + 2) + ';background:#fff;"><iframe width="100%" height="100%" style="border-width:0;background-color:#cccccc;display:block;" src="' + c + cnsUrlPath() + "cnst.php?prod=" + d + "&apiKey=" + window.cnsHostID + "&col2=" + window.cnsTwoColumnView + a + '"></iframe></div>';
  document.getElementById("cnsOverlayWrapper").style.display =
    "block"
}

function cnsOverlayClose() {
  // console.log("cnsOverlayClose()");
  document.getElementById("cnsOverlayWrapper").style.display = "none";
  document.getElementById("cnsOverlayWrapper").innerHTML = ""
}

function cnsCheckOverlayMargin() {
  "number" != typeof window.cnsOverlayMargin ? window.cnsOverlayMargin = 4 : 25 < window.cnsOverlayMargin ? window.cnsOverlayMargin = 25 : 0 > window.cnsOverlayMargin && (window.cnsOverlayMargin = 0)
}

function cnsOverlayNextHighZIndex(d) {
  1E4 > window.cnsOverlayZIndex && (window.cnsOverlayZIndex = 1E4);
  return window.cnsOverlayZIndex
};
