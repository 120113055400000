define("@algonauti/ember-active-storage/services/active-storage", ["exports", "@ember/utils", "@algonauti/ember-active-storage/model/blob", "rsvp", "@ember/service", "@algonauti/ember-active-storage/-private/uploader", "@ember/debug", "@ember/application"], function (_exports, _utils, _blob, _rsvp, _service, _uploader, _debug, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ActiveStorageService extends _service.default {
    get _config() {
      const config = (0, _application.getOwner)(this).resolveRegistration('config:environment') || {};
      return config['ember-active-storage'] || {};
    }
    upload(file, urlOrOptions, options = {}) {
      let url;
      if ((0, _utils.isPresent)(urlOrOptions)) {
        if ((0, _utils.typeOf)(urlOrOptions) == 'string') {
          url = urlOrOptions;
        } else if ((0, _utils.typeOf)(urlOrOptions) == 'object') {
          (true && !((0, _utils.isPresent)(this._config['url'])) && (0, _debug.assert)("If not explicitly passed, URL must be set on ENV['ember-active-storage'] = { url: '...' }", (0, _utils.isPresent)(this._config['url'])));
          options = urlOrOptions;
          url = this._config['url'];
        }
      } else {
        (true && !((0, _utils.isPresent)(this._config['url'])) && (0, _debug.assert)("If not explicitly passed, URL must be set on ENV['ember-active-storage'] = { url: '...' }", (0, _utils.isPresent)(this._config['url'])));
        url = this._config['url'];
      }
      let {
        metadata,
        ...callbacks
      } = options;
      const uploader = new _uploader.default({
        headers: this.headers,
        metadata: metadata,
        ...callbacks
      });
      return new _rsvp.Promise((resolve, reject) => {
        _blob.default.build(file).then(blob => {
          uploader.upload(blob, url, resolve, reject);
        });
      });
    }
  }
  _exports.default = ActiveStorageService;
});