define("@summit-electric-supply/ember-validators/index", ["exports", "@ember/debug", "@summit-electric-supply/ember-validators/collection", "@summit-electric-supply/ember-validators/confirmation", "@summit-electric-supply/ember-validators/date", "@summit-electric-supply/ember-validators/ds-error", "@summit-electric-supply/ember-validators/exclusion", "@summit-electric-supply/ember-validators/format", "@summit-electric-supply/ember-validators/inclusion", "@summit-electric-supply/ember-validators/index", "@summit-electric-supply/ember-validators/length", "@summit-electric-supply/ember-validators/messages", "@summit-electric-supply/ember-validators/number", "@summit-electric-supply/ember-validators/presence"], function (_exports, _debug, _collection, _confirmation, _date, _dsError, _exclusion, _format, _inclusion, _index, _length, _messages, _number, _presence) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.validate = validate;
  function validate(type, ...args) {
    switch (type) {
      case 'collection':
        return (0, _collection.default)(...args);
      case 'confirmation':
        return (0, _confirmation.default)(...args);
      case 'date':
        return (0, _date.default)(...args);
      case 'ds-error':
        return (0, _dsError.default)(...args);
      case 'exclusion':
        return (0, _exclusion.default)(...args);
      case 'format':
        return (0, _format.default)(...args);
      case 'inclusion':
        return (0, _inclusion.default)(...args);
      case 'index':
        return (0, _index.default)(...args);
      case 'length':
        return (0, _length.default)(...args);
      case 'messages':
        return (0, _messages.default)(...args);
      case 'number':
        return (0, _number.default)(...args);
      case 'presence':
        return (0, _presence.default)(...args);
      default:
        (true && !(false) && (0, _debug.assert)(`Validator not found of type: ${type}.`, false));
    }
  }
});