define("@summit-electric-supply/ember-cp-validations/-private/ember-internals", ["exports", "ember", "@ember/array"], function (_exports, _ember, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getDependentKeys = getDependentKeys;
  _exports.isDescriptor = isDescriptor;
  let __EMBER_METAL__;
  let emberMetalPaths = ['@ember/-internals/metal',
  // ember-source from 3.10
  '@ember/-internals/metal/index' // ember-source from 3.13
  ];

  let metalPath = (0, _array.A)(emberMetalPaths).find(path => _ember.default.__loader.registry[path]);
  if (metalPath) {
    __EMBER_METAL__ = _ember.default.__loader.require(metalPath);
  }
  function getDependentKeys(descriptorOrDecorator) {
    if (__EMBER_METAL__ && __EMBER_METAL__.descriptorForDecorator) {
      let descriptor = __EMBER_METAL__.descriptorForDecorator(descriptorOrDecorator);
      return descriptor._dependentKeys || [descriptor.altKey];
    } else {
      return descriptorOrDecorator._dependentKeys;
    }
  }
  function isDescriptor(o) {
    if (__EMBER_METAL__ && __EMBER_METAL__.isClassicDecorator) {
      return __EMBER_METAL__.isClassicDecorator(o);
    } else {
      return o && (typeof o === 'object' || typeof o === 'function') && o.isDescriptor;
    }
  }
});