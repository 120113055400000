define("@summit-electric-supply/ember-peak-pager/components/peak-pager", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield (component "peak-pager-segments" pages=this.pages route=@route)
          (component "peak-pager-link" query=(hash page=this.previousPage) route=@route)
          (component "peak-pager-link" query=(hash page=this.nextPage) route=@route)
          this.isBackEnabled
          this.isForwardEnabled}}
  
  */
  {
    "id": "AW8xm7ma",
    "block": "[[[18,2,[[50,\"peak-pager-segments\",0,null,[[\"pages\",\"route\"],[[30,0,[\"pages\"]],[30,1]]]],[50,\"peak-pager-link\",0,null,[[\"query\",\"route\"],[[28,[37,2],null,[[\"page\"],[[30,0,[\"previousPage\"]]]]],[30,1]]]],[50,\"peak-pager-link\",0,null,[[\"query\",\"route\"],[[28,[37,2],null,[[\"page\"],[[30,0,[\"nextPage\"]]]]],[30,1]]]],[30,0,[\"isBackEnabled\"]],[30,0,[\"isForwardEnabled\"]]]],[1,\"\\n\"]],[\"@route\",\"&default\"],false,[\"yield\",\"component\",\"hash\"]]",
    "moduleName": "@summit-electric-supply/ember-peak-pager/components/peak-pager.hbs",
    "isStrictMode": false
  });
  class PeakPagerComponent extends _component2.default {
    /**
     * The current page.
     */
    get page() {
      return parseInt(`${this.args.page || 1}`);
    }

    /**
     * The maximum amount of pages to show.
     */
    get show() {
      return parseInt(`${this.args.show || 10}`);
    }

    /**
     * The total amount of pages.
     */
    get total() {
      return parseInt(`${this.args.total || 0}`);
    }

    /**
     * The internal segments object.
     *
     * Based on https://github.com/mharris717/ember-cli-pagination/blob/master/addon/lib/truncate-pages.js
     */
    get segments() {
      let segments = [];
      let before = parseInt(this.show / 2);
      if (this.page - before < 1) {
        before = this.page - 1;
      }
      let after = this.show - before - 1;
      if (this.total - this.page < after) {
        after = this.total - this.page;
        before = this.show - after - 1;
      }
      if (this.page - before < 2) {
        after++;
      }
      if (this.total - this.page - 1 < after) {
        before++;
      }
      for (let i = before; i > 0; i--) {
        const segment = this.page - i;
        if (segment > 0 && segment <= this.total) {
          segments.push(segment);
        }
      }
      segments.push(this.page);
      for (let i = 1; i <= after; i++) {
        const segment = this.page + i;
        if (segment > 0 && segment <= this.total) {
          segments.push(segment);
        }
      }
      if (segments.length > 0) {
        if (segments[0] !== 1) {
          segments.unshift(1);
        }
        if (segments[segments.length - 1] !== this.total && this.total !== 0) {
          segments.push(this.total - 1, this.total);
        }
      }
      return segments;
    }

    /**
     * Pages array.
     *
     * Based on https://github.com/mharris717/ember-cli-pagination/blob/master/addon/lib/page-items.js
     */
    get pages() {
      const segments = this.segments;
      let next = segments[0];
      return segments.map(page => {
        const isCurrentPage = this.page === page;
        const isTruncated = next !== page;
        next = page + 1;
        return {
          isCurrentPage,
          isTruncated,
          page
        };
      });
    }
    get nextPage() {
      return this.page + 1;
    }
    get previousPage() {
      return this.page - 1;
    }
    get isBackEnabled() {
      return this.page > 1;
    }
    get isForwardEnabled() {
      return this.page < this.total;
    }
  }
  _exports.default = PeakPagerComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PeakPagerComponent);
});