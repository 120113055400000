define("@summit-electric-supply/ember-validators/collection", ["exports", "@ember/object", "@ember/debug", "@ember/array", "@ember/utils", "@summit-electric-supply/ember-validators/utils/validation-error"], function (_exports, _object, _debug, _array, _utils, _validationError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateCollection;
  /**
   *  @class Collection
   *  @module Validators
   */
  /**
    * @method validate
    * @param {Any} value
    * @param {Object} options
    * @param {Boolean} options.collection
    * @param {Object} model
    * @param {String} attribute
    */
  function validateCollection(value, options, model, attribute) {
    let collection = (0, _object.get)(options, 'collection');
    (true && !((0, _utils.isPresent)(collection)) && (0, _debug.assert)(`[validator:collection] [${attribute}] option 'collection' is required`, (0, _utils.isPresent)(collection)));
    if (collection === true && !(0, _array.isArray)(value)) {
      return (0, _validationError.default)('collection', value, options);
    }
    if (collection === false && (0, _array.isArray)(value)) {
      return (0, _validationError.default)('singular', value, options);
    }
    return true;
  }
});