define("@summit-electric-supply/ember-cp-validations/helpers/v-get", ["exports", "@ember/component/helper", "@ember/object"], function (_exports, _helper, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Transform:
   *  (v-get model 'username' 'isValid') to (get (get (get (get model 'validations') 'attrs') 'username') 'isValid')
   * OR
   *  (v-get model 'isValid') to (get (get model 'validations') 'isValid')
   * @param  {AST.Node} node
   * @return {AST.Node}
   */
  var _default = _exports.default = (0, _helper.helper)(function vGet(params) {
    const numParams = params.length;
    if (numParams < 2) {
      throw new Error('{{v-get}} requires at least two arguments');
    }
    const root = (0, _object.get)(params[0], 'validations');
    if (numParams === 3) {
      return (0, _object.get)(root, `attrs.${params[1]}.${params[2]}`);
    }
    return (0, _object.get)(root, params[1]);
  });
});