define("@summit-electric-supply/ember-validators/ds-error", ["exports", "@ember-data/model/-private", "@ember/object", "@ember/utils", "@summit-electric-supply/ember-validators/utils/validation-error"], function (_exports, _private, _object, _utils, _validationError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateDsError;
  _exports.getPathAndKey = getPathAndKey;
  /**
   *  @class DS Error
   *  @module Validators
   */
  /**
   * @method validate
   * @param {Any} value
   * @param {Object} options
   * @param {Object} model
   * @param {String} attribute
   */
  function validateDsError(value, options, model, attribute) {
    let {
      path,
      key
    } = getPathAndKey(attribute);
    let errors = (0, _object.get)(model, path);
    if (!(0, _utils.isNone)(errors) && errors instanceof _private.Errors && errors.has(key)) {
      return (0, _validationError.default)('ds', null, options, (0, _object.get)(errors.errorsFor(key), 'lastObject.message'));
    }
    return true;
  }
  function getPathAndKey(attribute) {
    let path = attribute.split('.');
    let key = path.pop();
    path.push('errors');
    return {
      path: path.join('.'),
      key
    };
  }
});