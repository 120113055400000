define("@summit-electric-supply/ember-cp-validations/validations/error", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @module Validations
   * @class Error
   */
  var _default = _exports.default = _object.default.extend({
    /**
     * The error validator type
     * @property type
     * @type {String}
     */
    type: null,
    /**
     * The error message
     * @property message
     * @type {String}
     */
    message: null,
    /**
     * The attribute that the error belongs to
     * @property attribute
     * @type {String}
     */
    attribute: null,
    /**
     * The parent attribute that the error belongs to
     * @property parentAttribute
     * @type {String}
     */
    parentAttribute: null
  });
});