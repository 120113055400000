define("@summit-electric-supply/ember-peak-pager/components/peak-pager-link", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <LinkTo @route={{@route}} @query={{@query}} ...attributes>
    {{yield}}
  </LinkTo>
  
  */
  {
    "id": "/B94Or6C",
    "block": "[[[8,[39,0],[[17,1]],[[\"@route\",\"@query\"],[[30,2],[30,3]]],[[\"default\"],[[[[1,\"\\n  \"],[18,4,null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"&attrs\",\"@route\",\"@query\",\"&default\"],false,[\"link-to\",\"yield\"]]",
    "moduleName": "@summit-electric-supply/ember-peak-pager/components/peak-pager-link.hbs",
    "isStrictMode": false
  });
  class PeakPagerLinkComponent extends _component2.default {}
  _exports.default = PeakPagerLinkComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PeakPagerLinkComponent);
});