define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = [["en-us", {
    "account-history-orders": "Orders and POD",
    "account-history-returns": "Returns History",
    "account-history-statements": "Statements",
    "account-payments": "Payment History",
    "account-project-release.confirmation": "The requested item has already been released. Are you sure you would like to add this material anyway? Your release will be unable to be approved if the requested quantity exceeds what is available.",
    "account-project-release.status.deny": "Rejected",
    "account-project-release.status.new": "New",
    "account-project-release.status.pending": "Pending",
    "account-project-release.status.released": "Sent to Summit",
    "account-projects": "Projects",
    "account-purchase": "Purchase",
    "account-quotes": "Quotes",
    "accounts-history-invoices": "Invoices",
    "admin": "Admin",
    "admin-accounts": "Business Accounts",
    "admin-accounts-verifications": "Verifications",
    "admin-customers": "Admin Area",
    "admin-dynamic-translations": "Dynamic Translations",
    "admin-projects": "My Projects",
    "admin-reports": "Reports",
    "admin-service-centers": "Service centers",
    "adminCredit": "Admin Credit",
    "adminIT": "Admin IT",
    "adminMarketing": "Admin Marketing",
    "application": "Home",
    "associate": "Associate",
    "balance": "Bill Pay",
    "categories.all.id": "0f379757-1f89-51c8-adf5-716097eceb87",
    "categories.all.label": "View All Categories",
    "checkout.web-order.details.model.error": "An error occurred. Please try again. If this error persists, please call +18554044800 or email info@summit.com and we will be happy to help.",
    "choice-partners": "Choice Partners",
    "choice-partners.eaton": "Eaton",
    "choice-partners.eaton.exit": "Exit",
    "choice-partners.eaton.flood-fixtures": "Flood Fixtures",
    "choice-partners.eaton.high-bay": "High Bay",
    "choice-partners.eaton.layin": "Layin",
    "choice-partners.eaton.led-retro-trim": "LED Retro Trim",
    "choice-partners.eaton.pole-site-area": "Pole/Site/Area",
    "choice-partners.eaton.stairwell-wall": "Stairwell/Wall",
    "choice-partners.eaton.strip": "Strip",
    "choice-partners.eaton.under-cabinet": "Under Cabinet",
    "choice-partners.eaton.wall-packs": "Wall Packs",
    "choice-partners.eaton.wrap-around": "Wrap Around",
    "choice-partners.lutron": "Lutron",
    "choice-partners.lutron.government": "Government",
    "choice-partners.lutron.schools": "Schools",
    "choice-partners.sylvania": "Sylvania",
    "choice-partners.sylvania.fluorescent-lamps-and-ballasts": "Fluorescent Lamps and Ballasts",
    "choice-partners.sylvania.hid-lamps-and-ballast": "HID Lamps and Ballasts",
    "choice-partners.sylvania.incandescent-halogen-lamps": "Incandescent/Halogen Lamps",
    "choice-partners.sylvania.led-lamps": "LED Lamps",
    "choice-partners.sylvania.led-luminaires": "LED Luminaires",
    "choice-partners.sylvania.led-retrofit-systems": "LED Retrofit Systems and Kits",
    "choice-partners.universal": "Universal",
    "copyright": "© {year} Summit Electric Supply™ All rights reserved",
    "customer": "Customer",
    "customer.financial": "Financial",
    "customer.line_item_ship_to": "Cart Line Item Delivery",
    "customer.manageuser": "Manage Users",
    "customer.ordering": "Ordering",
    "customer.projects": "My Projects",
    "customer.punchout": "Punchout",
    "customer.remove_realtime_inventory": "Remove Realtime Inventory",
    "customer.restrictions_service_center": "Restrictions Service Center",
    "customerAdmin": "Customer Admin",
    "images.product.default": "https://s3.amazonaws.com/summit-website-assets/images/Product-Image-Not-Available-1.jpg",
    "images.product.label": "Product Image Not Available",
    "product_availability::read": "Remove Realtime Inventory",
    "punchout": "Punchout",
    "service_center::read": "Restrict Service Centers",
    "slides.coronavirus.image": "https://summit-website-assets.s3.amazonaws.com/images/Home+Page+Slides/Homepage-COVID-19-2.jpg",
    "slides.coronavirus.label": "We Are Open and Here to Help. Learn about our COVID-19 response.",
    "store.products.index.error.invalid-quantity": "Please enter a valid quantity in multiples of {minimumQuantity} {unitOfMeasure}.",
    "store.products.index.error.invalid-selected-quantity": "Please enter a quantity equal to or less than {availableQuantity} {unitOfMeasure}.",
    "switch-accounts": "Swtich Customer Accounts",
    "switch-customer": "Switch Customer"
  }]];
});