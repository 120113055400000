define("@summit-electric-supply/ember-scroll-to-target/components/scroll-to", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@glimmer/component"], function (_exports, _component, _templateFactory, _object, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <button {{on "click" this.scrollToTarget}} class={{this.class}} classNames={{this.classNames}} ...attributes>
    {{this.label}}
  </button>
  {{yield}}
  
  */
  {
    "id": "nETzbIP0",
    "block": "[[[11,\"button\"],[16,0,[30,0,[\"class\"]]],[16,\"classNames\",[30,0,[\"classNames\"]]],[17,1],[4,[38,0],[\"click\",[30,0,[\"scrollToTarget\"]]],null],[12],[1,\"\\n  \"],[1,[30,0,[\"label\"]]],[1,\"\\n\"],[13],[1,\"\\n\"],[18,2,null],[1,\"\\n\"]],[\"&attrs\",\"&default\"],false,[\"on\",\"yield\"]]",
    "moduleName": "@summit-electric-supply/ember-scroll-to-target/components/scroll-to.hbs",
    "isStrictMode": false
  });
  let scrollToTargetComponent = _exports.default = (_class = class scrollToTargetComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "label", this.args.label ?? "");
      _defineProperty(this, "tagName", this.args.tagName ?? null);
      _defineProperty(this, "target", this.args.target ?? null);
      _defineProperty(this, "offset", this.args.offset ?? 0);
      _defineProperty(this, "duration", this.args.duration ?? 500);
      _defineProperty(this, "classNames", this.args.classNames ?? null);
      _defineProperty(this, "class", this.args.class ?? null);
    }
    scrollToTarget() {
      const target = this.target;
      const offset = this.offset;
      const duration = this.duration;
      if (!target) {
        Ember.Logger.error("Target should be passed");
        return;
      }
      const targetPos = document.querySelector(target).offsetTop + offset;
      this.animateScroll(targetPos, duration);
    }
    animateScroll(targetPos, duration) {
      const self = this;
      const startPos = window.scrollY;
      const speed = 20;
      let time = 0;
      const animate = function () {
        time += speed;
        window.scrollTo(0, self.getAnimationPos(time, startPos, targetPos - startPos, duration));
        if (time < duration) {
          setTimeout(animate, speed);
        }
      };
      animate();
    }
    getAnimationPos(time, startPos, endPos, duration) {
      time /= duration / 2;
      if (time < 1) {
        return endPos / 2 * time * time + startPos;
      }
      time--;
      return -endPos / 2 * (time * (time - 2) - 1) + startPos;
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "scrollToTarget", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "scrollToTarget"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, scrollToTargetComponent);
});