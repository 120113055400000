define("@summit-electric-supply/ember-validators/utils/is-promise", ["exports", "@summit-electric-supply/ember-validators/utils/can-invoke"], function (_exports, _canInvoke) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isPromise;
  function isPromise(p) {
    return !!(p && (0, _canInvoke.default)(p, 'then'));
  }
});