define("@summit-electric-supply/ember-cp-validations/validators/ds-error", ["exports", "@summit-electric-supply/ember-cp-validations/-private/ember-validator", "@summit-electric-supply/ember-validators/ds-error"], function (_exports, _emberValidator, _dsError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   *  <i class="fa fa-hand-o-right" aria-hidden="true"></i> [See All Options](#method_validate)
   *
   *  Creates a link between this library and Ember-Data's [DS.Errors](http://emberjs.com/api/data/classes/DS.Errors.html)
   *  to fetch the latest message for the given attribute.
   *
   *  ## Examples
   *
   *  ```javascript
   *  validator('ds-error')
   *  ```
   *
   *  @class DS Error
   *  @module Validators
   *  @extends Base
   */
  const DSError = _emberValidator.default.extend({
    _evType: 'ds-error'
  });
  DSError.reopenClass({
    getDependentsFor(attribute) {
      let {
        path,
        key
      } = (0, _dsError.getPathAndKey)(attribute);
      return [`model.${path}.${key}.[]`];
    }
  });
  var _default = _exports.default = DSError;
});