define("@summit-electric-supply/ember-cp-validations/utils/utils", ["exports", "@summit-electric-supply/ember-cp-validations/-private/ember-internals", "@ember/array", "@ember/polyfills", "@ember/template", "@ember-data/model/-private", "@ember/utils", "@ember/array/proxy", "@summit-electric-supply/ember-validators/utils/can-invoke", "@ember/object", "@ember/object/proxy", "@ember-data/model"], function (_exports, _emberInternals, _array, _polyfills, _template, _private, _utils, _proxy, _canInvoke, _object, _proxy2, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "getDependentKeys", {
    enumerable: true,
    get: function () {
      return _emberInternals.getDependentKeys;
    }
  });
  _exports.getValidatableValue = getValidatableValue;
  _exports.isDSManyArray = isDSManyArray;
  Object.defineProperty(_exports, "isDescriptor", {
    enumerable: true,
    get: function () {
      return _emberInternals.isDescriptor;
    }
  });
  _exports.isDsModel = isDsModel;
  _exports.isEmberObject = isEmberObject;
  _exports.isObject = isObject;
  _exports.isPromise = isPromise;
  _exports.isProxy = isProxy;
  _exports.isValidatable = isValidatable;
  _exports.mergeOptions = mergeOptions;
  _exports.unwrapProxy = unwrapProxy;
  _exports.unwrapString = unwrapString;
  function unwrapString(s) {
    if ((0, _template.isHTMLSafe)(s)) {
      return s.toString();
    }
    return s;
  }
  function unwrapProxy(o) {
    return isProxy(o) ? unwrapProxy((0, _object.get)(o, 'content')) : o;
  }
  function isProxy(o) {
    return !!(o && (o instanceof _proxy2.default || o instanceof _proxy.default));
  }
  function isPromise(p) {
    return !!(p && (0, _canInvoke.default)(p, 'then'));
  }
  function isDsModel(o) {
    return !!(o && o instanceof _model.default);
  }
  function isDSManyArray(o) {
    return !!(o && (0, _array.isArray)(o) && (o instanceof _private.PromiseManyArray || o instanceof _private.ManyArray));
  }
  function isEmberObject(o) {
    return !!(o && o instanceof _object.default);
  }
  function isObject(o) {
    return (0, _utils.typeOf)(o) === 'object' || (0, _utils.typeOf)(o) === 'instance';
  }
  function isValidatable(value) {
    let v = unwrapProxy(value);
    return isDsModel(v) ? !(0, _object.get)(v, 'isDeleted') : true;
  }
  function getValidatableValue(value) {
    if (!value) {
      return value;
    }
    if (isDSManyArray(value)) {
      return (0, _array.A)(value.filter(v => isValidatable(v)));
    }
    return isValidatable(value) ? value : undefined;
  }
  function mergeOptions(...options) {
    let o = {};
    for (let i = options.length - 1; i >= 0; i--) {
      let _o = options[i];
      (0, _polyfills.assign)(o, isObject(_o) ? _o : {});
    }
    return o;
  }
});