define("@summit-electric-supply/ember-peak-pager/components/peak-pager-segments", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#each @pages as |page|}}
    {{yield page.page
            (component "peak-pager-link" query=(hash page=page.page) route=@route)
            page.isCurrentPage
            page.isTruncated}}
  {{/each}}
  
  */
  {
    "id": "GgXPzyOr",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,1]],null]],null],null,[[[1,\"  \"],[18,4,[[30,2,[\"page\"]],[50,\"peak-pager-link\",0,null,[[\"query\",\"route\"],[[28,[37,4],null,[[\"page\"],[[30,2,[\"page\"]]]]],[30,3]]]],[30,2,[\"isCurrentPage\"]],[30,2,[\"isTruncated\"]]]],[1,\"\\n\"]],[2]],null]],[\"@pages\",\"page\",\"@route\",\"&default\"],false,[\"each\",\"-track-array\",\"yield\",\"component\",\"hash\"]]",
    "moduleName": "@summit-electric-supply/ember-peak-pager/components/peak-pager-segments.hbs",
    "isStrictMode": false
  });
  class PeakPagerSegmentsComponent extends _component2.default {}
  _exports.default = PeakPagerSegmentsComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PeakPagerSegmentsComponent);
});